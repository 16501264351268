import React, { useState } from "react"
import styles from "./accordion.module.scss"
import AnimateHeight from "react-animate-height"

const data = [
  {
    name: "goal",
    title: "Зачем нужна CRM-система для спортивных школ?",
    content: `CRM-система для спортивных клубов - это облачная программа, разработанная специально для учета клиентов, посещений и абонементов. CRM поможет сократить время на организацию грамотного и прозрачного учета. Для этого вам нужно только внести первичные данные в систему, а далее CRM автоматически будет рассчитывать зарплату тренерам, составлять отчеты, рассылать уведомления, напоминать сотрудникам о задачах и так далее. Большой плюс CRM-системы для спорта - это наличие персонального кабинета ученика с его личным расписанием, возможностью онлайн-оплаты абонементов, отчетом по посещениям и напоминаниями о предстоящих тренировках.`,
  },
  {
    name: "features",
    title: "Какие функции важны в CRM для спортивных клубов?",
    content: `Самое главное - объединение нескольких сервисов в одной системе. В Параплан CRM руководителю школы не потребуется дополнительно вести систему для продаж или, например, оплачивать таск-менеджер. В нашей CRM уже есть готовая воронка продаж, куда автоматически попадают лиды из разных источников. Далее в планировщике можно ставить задачи по клиентам. Важная опция - сбор лидов через сайт и социальные сети. Для этой цели используйте онлайн-расписание занятий или виджет сбора контактов. Записи будут приходить сразу в воронку продаж. Также в CRM для спортивных школ важно наличие инструментов контроля сотрудников. Мы предлагаем вам подключить интеграцию с IP-телефонией, чтобы прослушивать записи звонков, и интеграцию с мессенджерами WhatsApp и Telegram для отслеживания переписок с клиентами внутри CRM-системы.`,
  },
  {
    name: "loyalty",
    title: "Как CRM для спортивных секций помогает повышать лояльность клиентов?",
    content: `После блокировки основных рекламных каналов спортивные организации должны сосредоточиться на повышении клиентского сервиса. Специализированная CRM-система уже включает в себя готовые инструменты, которые помогут удерживать учеников. Во-первых, Личный кабинет клиента. В нем спортсмен сможет видеть свое расписание, историю посещений и оплат, действующие абонементы. Во-вторых, автоуведомления о предстоящих тренировках, об отмене или переносе занятия, о скором завершении срока действия абонемента. Согласитесь, приятно, когда администрация клуба заботится о своих клиентах и своевременно сообщает ему обо всех изменениях. В-третьих, SMS-рассылки с акциями, поздравлениями, приглашениями. Администратор школы может отфильтровать конкретную группу клиентов и сделать персонализированную рассылку.`,
  },
]
const descr = "<p>Нужна ли спортивной школе CRM-система? Мы в Параплан CRM уверены, что да! Контролировать работу клуба в режиме онлайн и сокращать количество ручных действий необходимо. И мы подскажем, как быстро выбрать CRM для спортивной школы.\n" +
  "</p><p>Рекомендуем обратить внимание на специальные CRM-системы для спорта. В них уже есть готовые блоки и интеграции для построения рабочего процесса от и до: абонементная система, зарплатные ставки тренеров, статусы посещений клиентов, Личный кабинет спортсмена с возможностью онлайн оплаты, кабинет тренера с персональным расписанием и многое другое.\n" +
  "</p><p>Ваши клиенты смогут самостоятельно записываться на занятия через онлайн-расписание на сайте, что снижает нагрузку на администратора клуба. А автоматические уведомления напомнят клиенту о предстоящей тренировке или об окончании абонемента. CRM-система помогает спортивным школам быстрее обслуживать клиентов и не терять их.\n" +
  "</p><p>Перед покупкой тщательно тестируйте CRM во время бесплатного пробного периода. CRM-система - это не просто программа для ведения продаж. Проверьте особенности оформления абонементов и принципы списания занятий, вид и удобство расписания, в том числе онлайн-записи на тренировки, настройки автоматических действий, назначение ролей сотрудникам, готовые интеграции, доступные отчеты и аналитику. Помните, CRM должна освободить вас от энергопрожорливых процессов.\n" +
  "</p><p>Последний шаг - сравните тарифные сетки нескольких СRM-систем для спортивных школ. Уточните, какие ограничения по пользователям, количеству клиентов или группам. Держите подсказку: чем дольше период подписки на систему, тем выгоднее ее стоимость.</p>"

const AccordionComponent = () => {
  const [tab, setTab] = useState(null)
  return <div className={`container ${styles.tabs}`}>
    <div className={styles.descr}>
      <h2 className={styles.heading}>Советы по внедрению CRM-системы в спортивных школах</h2>
      <div className={styles.content} dangerouslySetInnerHTML={{__html: descr }}></div>
    </div>
    <h2 className={styles.heading}>FAQs</h2>
    {data.map(t =>
      <div key={t.name}
           className={`${styles.tab} ${t.name === (tab && tab.name) ? styles.open : ""}`}
           onClick={() => setTab(t !== tab ? t : null)}>
        <div className={styles.info}>
          <div className={styles.title}>{t.title}</div>
          <AnimateHeight duration={300} height={t.name === (tab && tab.name) ? "auto" : 0}>
            <div className={styles.content}>{t.content}</div>
          </AnimateHeight>
        </div>
        <div className={styles.chevron}>
          <svg width="20" height="12" viewBox="0 0 20 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.05287 11.2318L0.435026 2.66346C0.367169 1.27492 1.01181 0.631447 2.33502 0.733047L9.90108 8.28535L17.4671 0.733047C18.7903 0.631447 19.435 1.27492 19.3671 2.66346L10.7832 11.2318C10.1386 11.8414 9.66358 11.8414 9.05287 11.2318Z"
              fill="currentColor"/>
          </svg>
        </div>
      </div>,
    )}
  </div>
}

export default AccordionComponent
